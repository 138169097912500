// assets
import {
    IconUsers,
    IconSitemap,
    IconApps,
    IconSettings,
    IconKey,
    IconBook,
    IconDatabaseExport
} from '@tabler/icons';

// constant
const icons = {
    IconUsers: IconUsers,
    IconSitemap: IconSitemap,
    IconApps: IconApps,
    IconSettings: IconSettings,
    IconKey: IconKey,
    IconBook: IconBook,
    IconDatabaseExport: IconDatabaseExport
};

//-----------------------|| UTILITIES MENU ITEMS ||-----------------------//

export const settings = {
    id: 'settings',
    title: 'Pengaturan',
    type: 'group',
    children: [
        {
            id: 'setting-user',
            title: 'Pengguna',
            type: 'item',
            url: '/setting/user',
            icon: icons['IconUsers'],
            breadcrumbs: false
        },
        {
            id: 'setting-user-level',
            title: 'Level Pengguna',
            type: 'item',
            url: '/setting/leveluser',
            icon: icons['IconSitemap'],
            breadcrumbs: false
        },
        {
            id: 'setting-product-group',
            title: 'Kelompok Barang',
            type: 'item',
            url: '/setting/kelompokbarang',
            icon: icons['IconApps'],
            breadcrumbs: false
        },
        {
            id: 'setting-close-trans',
            title: 'Tutup Buku',
            type: 'item',
            url: '/setting/close-trans',
            icon: icons['IconBook'],
            breadcrumbs: false
        },
        {
            id: 'setting-store',
            title: 'Pengaturan Toko',
            type: 'item',
            url: '/setting/store-setting',
            icon: icons['IconSettings'],
            breadcrumbs: false
        },
        {
            id: 'setting-access-level',
            title: 'Pengaturan Akses',
            type: 'item',
            url: '/setting/access-setting',
            icon: icons['IconKey'],
            breadcrumbs: false
        }
    ]
};
