// dashboard routing
import { lazy } from 'react';
import Loadable from '../ui-component/Loadable';

const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));
const Product = Loadable(lazy(() => import('../views/product/Product')))
const ProductAdd = Loadable(lazy(() => import('../views/product/ProductAdd')))
const ProductEdit = Loadable(lazy(() => import('../views/product/ProductEdit')))
const Customer = Loadable(lazy(() => import('../views/customer/Customer')))
const CustomerAdd = Loadable(lazy(() => import('../views/customer/CustomerAdd')))
const CustomerEdit = Loadable(lazy(() => import('../views/customer/CustomerEdit')))
const Supplier = Loadable(lazy(() => import('../views/supplier/Supplier')))
const SupplierAdd = Loadable(lazy(() => import('../views/supplier/SupplierAdd')))
const SupplierEdit = Loadable(lazy(() => import('../views/supplier/SupplierEdit')))
const Gudang = Loadable(lazy(() => import('../views/gudang/Gudang')))
const GudangAdd = Loadable(lazy(() => import('../views/gudang/GudangAdd')))
const GudangEdit = Loadable(lazy(() => import('../views/gudang/GudangEdit')))
const Province = Loadable(lazy(() => import('../views/province/Province')))
const ProvinceAdd = Loadable(lazy(() => import('../views/province/ProvinceAdd')))
const ProvinceEdit = Loadable(lazy(() => import('../views/province/ProvinceEdit')))
const City = Loadable(lazy(() => import('../views/city/City')))
const CityAdd = Loadable(lazy(() => import('../views/city/CityAdd')))
const CityEdit = Loadable(lazy(() => import('../views/city/CityEdit')))
const User = Loadable(lazy(() => import('../views/user/User')))
const UserAdd = Loadable(lazy(() => import('../views/user/UserAdd')))
const UserEdit = Loadable(lazy(() => import('../views/user/UserEdit')))
const UserLevel = Loadable(lazy(() => import('../views/user_level/UserLevel')))
const UserLevelAdd = Loadable(lazy(() => import('../views/user_level/UserLevelAdd')))
const UserLevelEdit = Loadable(lazy(() => import('../views/user_level/UserLevelEdit')))
const Reseller = Loadable(lazy(() => import('../views/reseller/Reseller')))
const ResellerAdd = Loadable(lazy(() => import('../views/reseller/ResellerAdd')))
const ResellerEdit = Loadable(lazy(() => import('../views/reseller/ResellerEdit')))
const ProductGroup = Loadable(lazy(() => import('../views/productgroup/ProductGroup')))
const ProductGroupAdd = Loadable(lazy(() => import('../views/productgroup/ProductGroupAdd')))
const ProductGroupEdit = Loadable(lazy(() => import('../views/productgroup/ProductGroupEdit')))
const SalesOrder = Loadable(lazy(() => import('../views/sales_order/SalesOrder')))
const SalesOrderAdd = Loadable(lazy(() => import('../views/sales_order/SalesOrderAdd')))
const SalesOrderEdit = Loadable(lazy(() => import('../views/sales_order/SalesOrderEdit')))
const PurchaseOrder = Loadable(lazy(() => import('../views/purchase_order/PurchaseOrder')))
const PurchaseOrderAdd = Loadable(lazy(() => import('../views/purchase_order/PurchaseOrderAdd')))
const PurchaseOrderEdit = Loadable(lazy(() => import('../views/purchase_order/PurchaseOrderEdit')))
const Piutang = Loadable(lazy(() => import('../views/piutang/Piutang')))
const SalesReturn = Loadable(lazy(() => import('../views/retur/Retur')))
const SalesReturnAdd = Loadable(lazy(() => import('../views/retur/ReturAdd')))
const Report = Loadable(lazy(() => import('../views/report/Report')))
const Setting = Loadable(lazy(() => import('../views/setting/Setting')))
const AccessSetting  = Loadable(lazy(() => import('../views/setting/AccessSetting')))
const DeleteTransSetting  = Loadable(lazy(() => import('../views/setting/DeleteTransSetting')))
const ArchiveDatabaseSetting  = Loadable(lazy(() => import('../views/setting/ArchiveDatabaseSetting')))
const ExcessPurchase = Loadable(lazy(() => import('../views/excess_purchase/ExcessPurchase')))
const ExcessPurchaseAdd = Loadable(lazy(() => import('../views/excess_purchase/ExcessPurchaseAdd')))
const ExcessPurchaseEdit = Loadable(lazy(() => import('../views/excess_purchase/ExcessPurchaseEdit')))
const ExcessPurchaseAddUsage = Loadable(lazy(() => import('../views/excess_purchase/ExcessPurchaseAddUsage')))
const ExcessPurchaseEditUsage = Loadable(lazy(() => import('../views/excess_purchase/ExcessPurchaseEditUsage')))
const ProductVariant = Loadable(lazy(() => import('../views/productvariant/ProductVariant')))
const ProductVariantAdd = Loadable(lazy(() => import('../views/productvariant/ProductVariantAdd')))
const ProductVariantEdit = Loadable(lazy(() => import('../views/productvariant/ProductVariantEdit')))
const StockReserve = Loadable(lazy(() => import('../views/stock_reserve/StockReserve')))
const StockReserveAdd = Loadable(lazy(() => import('../views/stock_reserve/StockReserveAdd')))
const StockReserveEdit = Loadable(lazy(() => import('../views/stock_reserve/StockReserveEdit')))
const StockReserveDistribute = Loadable(lazy(() => import('../views/stock_reserve/StockReserveDistribute')))
const OperationalCost = Loadable(lazy(() => import('../views/operational_cost/OperationalCost')))
const OperationalCostAdd = Loadable(lazy(() => import('../views/operational_cost/OperationalCostAdd')))
const OperationalCostAddV2 = Loadable(lazy(() => import('../views/operational_cost/OperationalCostAddV2')))
const OperationalCostEdit = Loadable(lazy(() => import('../views/operational_cost/OperationalCostEdit')))
const OperationalCostEditV2 = Loadable(lazy(() => import('../views/operational_cost/OperationalCostEditV2')))
const GlobalStock = Loadable(lazy(() => import('../views/global_stock/GlobalStock')))
const Sales = Loadable(lazy(() => import('../views/sales/Sales')))
const CodKurir = Loadable(lazy(() => import('../views/cod_kurir/CodKurir')))
const CloseTransaction = Loadable(lazy(() => import('../views/close_transaction/CloseTransaction')))

export const routes = [
    { path: '/dashboard/default', component: DashboardDefault, exact: undefined },
    { path: '/report/export', component: Report, exact: true },
    { path: '/order/purchase', component: PurchaseOrder, exact: true },
    { path: '/order/purchase/add', component: PurchaseOrderAdd, exact: undefined },
    { path: '/order/purchase/edit/:id', component: PurchaseOrderEdit, exact: undefined },
    { path: '/order/sales', component: SalesOrder, exact: true },
    { path: '/order/sales/add', component: SalesOrderAdd, exact: undefined },
    { path: '/order/sales/edit/:id', component: SalesOrderEdit, exact: undefined },
    { path: '/order/sales/piutang', component: Piutang, exact: true },
    { path: '/order/sales/return', component: SalesReturn, exact: true },
    { path: '/order/sales/return/add', component: SalesReturnAdd, exact: true },
    { path: '/excess-purchase', component: ExcessPurchase, exact: true },
    { path: '/excess-purchase/add', component: ExcessPurchaseAdd, exact: false },
    { path: '/excess-purchase/edit/:id', component: ExcessPurchaseEdit, exact: false },
    { path: '/excess-purchase/usage/add/:gudangid/:productid/:variantid/:fromreserve', component: ExcessPurchaseAddUsage, exact: false },
    { path: '/excess-purchase/usage/edit/:gudangid/:productid/:variantid/:fromreserve/:usageid', component: ExcessPurchaseEditUsage, exact: false },
    { path: '/transaction/stock-reserve', component: StockReserve, exact: true },
    { path: '/transaction/stock-reserve/add', component: StockReserveAdd, exact: undefined },
    { path: '/transaction/stock-reserve/edit/:supplierid', component: StockReserveEdit, exact: undefined },
    { path: '/transaction/stock-reserve/distribute/:stockreserveitemid', component: StockReserveDistribute, exact: undefined },
    { path: '/transaction/global-stock', component: GlobalStock, exact: true },
    { path: '/transaction/operational-cost', component: OperationalCost, exact: true },
    { path: '/transaction/operational-cost/add', component: OperationalCostAddV2, exact: undefined },
    { path: '/transaction/operational-cost/edit/:transdate/:gudangid/:categoryid/:kuririd', component: OperationalCostEditV2, exact: undefined },
    { path: '/transaction/cod-kurir', component: CodKurir, exact: true },
    { path: '/master-data/product', component: Product, exact: true },
    { path: '/master-data/product/add', component: ProductAdd, exact: undefined },
    { path: '/master-data/product/edit/:id', component: ProductEdit, exact: undefined },
    { path: '/master-data/customer', component: Customer, exact: true },
    { path: '/master-data/customer/add', component: CustomerAdd, exact: undefined },
    { path: '/master-data/customer/edit/:id', component: CustomerEdit, exact: undefined },
    { path: '/master-data/supplier', component: Supplier, exact: true },
    { path: '/master-data/supplier/add', component: SupplierAdd, exact: undefined },
    { path: '/master-data/supplier/edit/:id', component: SupplierEdit, exact: undefined },
    { path: '/master-data/gudang', component: Gudang, exact: true },
    { path: '/master-data/gudang/add', component: GudangAdd, exact: undefined },
    { path: '/master-data/gudang/edit/:id', component: GudangEdit, exact: undefined },
    { path: '/master-data/province', component: Province, exact: true },
    { path: '/master-data/province/add', component: ProvinceAdd, exact: undefined },
    { path: '/master-data/province/edit/:id', component: ProvinceEdit, exact: undefined },
    { path: '/master-data/city', component: City, exact: true },
    { path: '/master-data/city/add', component: CityAdd, exact: undefined },
    { path: '/master-data/city/edit/:id', component: CityEdit, exact: undefined },
    { path: '/master-data/sales', component: Sales, exact: true },
    { path: '/setting/user', component: User, exact: true },
    { path: '/setting/user/add/:fromsales', component: UserAdd, exact: undefined },
    { path: '/setting/user/edit/:id/:fromsales', component: UserEdit, exact: undefined },
    { path: '/setting/leveluser', component: UserLevel, exact: true },
    { path: '/setting/leveluser/add', component: UserLevelAdd, exact: undefined },
    { path: '/setting/leveluser/edit/:id', component: UserLevelEdit, exact: undefined },
    { path: '/setting/reseller', component: Reseller, exact: true },
    { path: '/setting/reseller/add/:salesid/:salesname', component: ResellerAdd, exact: undefined },
    { path: '/setting/reseller/edit/:id', component: ResellerEdit, exact: undefined },
    { path: '/setting/kelompokbarang', component: ProductGroup, exact: true },
    { path: '/setting/kelompokbarang/add', component: ProductGroupAdd, exact: undefined },
    { path: '/setting/kelompokbarang/edit/:id', component: ProductGroupEdit, exact: undefined },
    { path: '/setting/variasibarang', component: ProductVariant, exact: true },
    { path: '/setting/variasibarang/add', component: ProductVariantAdd, exact: undefined },
    { path: '/setting/variasibarang/edit/:id', component: ProductVariantEdit, exact: undefined },
    { path: '/setting/store-setting', component: Setting, exact: true },
    { path: '/setting/access-setting', component: AccessSetting, exact: true },
    { path: '/setting/delete-trans-setting', component: DeleteTransSetting, exact: true },
    { path: '/setting/close-trans', component: CloseTransaction, exact: true },
    // { path: '/setting/archive-db', component: ArchiveDatabaseSetting, exact: true }
]